import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ArrowReturnIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.146 8.646a.5.5 0 0 0 0 .708l5.243 5.242a.5.5 0 0 0 .707 0l.707-.707a.5.5 0 0 0 0-.707L7.621 10H16.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H7.621l3.182-3.182a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0z"></path>
      <path d="M13 19a1 1 0 1 1 0-2zm0-2h4v2h-4zm6-2v-3h2v3zm-2-5h-1.5V8H17zm2 2a2 2 0 0 0-2-2V8a4 4 0 0 1 4 4zm-2 5a2 2 0 0 0 2-2h2a4 4 0 0 1-4 4z"></path>
    </svg>
  );
};

export default ArrowReturnIcon;
