import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M9.748 12.338C8.266 11.77 7.16 10.684 7.16 8.764c0-2.208 1.584-3.864 4.032-4.104V2.5h2.16v2.28c1.076.225 1.93.705 2.563 1.391l2.378-2.378a1 1 0 111.414 1.414l-14.5 14.5a1 1 0 01-1.414-1.414l5.955-5.955zm1.816-1.816l2.786-2.786c-.49-.644-1.311-1.084-2.51-1.084-1.488 0-2.424.792-2.424 1.944 0 .96.6 1.536 1.824 1.848l.324.078z"
        clipRule="evenodd"
      ></path>
      <path d="M11.192 21.484h2.16v-2.376c2.304-.336 3.864-1.824 3.864-4.152 0-1.214-.468-2.166-1.276-2.852a.462.462 0 00-.625.041l-.868.869c-.243.243-.163.648.078.892.303.306.435.69.435 1.17 0 1.296-1.008 2.04-2.64 2.04-.479 0-.922-.065-1.318-.2a.557.557 0 00-.575.118l-.902.901c-.232.232-.181.62.12.75.472.2.99.343 1.547.423v2.376z"></path>
    </svg>
  );
};

export default Icon;
