import { SchedulerReschedulingOptionsType } from "@lib/data/schemas/scheduler";

import BlockIcon from "@components/Icons/BlockIcon";
import FilledFlagIcon from "@components/Icons/FilledFlagIcon";
import FreeIcon from "@components/Icons/FreeIcon";
import GhostyIcon from "@components/Icons/GhostyIcon";
import RescheduleOutcomeIcon from "@components/Icons/RescheduleOutcomeIcon";

export const OUTCOME_ICONS: { [key: string]: any } = {
  ic_ghost: GhostyIcon,
  ic_cancel: BlockIcon,
  ic_complete: FilledFlagIcon,
  ic_reschedule: RescheduleOutcomeIcon,
  ic_free: FreeIcon,
};

export const getNoticeOptions = (
  reschedulingOptions?: SchedulerReschedulingOptionsType,
  itemId?: string
): {
  minimumNoticeDuration: number;
  hasMinimumNotice: boolean;
  noticeReasonRequired: boolean;
} => {
  const { minimumNotice, reasonRequired } = reschedulingOptions || {};

  const minimumNoticeDuration =
    minimumNotice?.duration && minimumNotice.duration > 0
      ? minimumNotice?.duration / 60
      : 24;

  const hasMinimumNotice =
    minimumNotice?.duration !== undefined
      ? minimumNotice.duration > 0
      : !itemId;

  const noticeReasonRequired =
    reasonRequired !== undefined ? reasonRequired : !itemId;

  return {
    minimumNoticeDuration,
    hasMinimumNotice,
    noticeReasonRequired,
  };
};
